<template>
  <div class="orders">
    <OrderPageHeader
      data-testid="ordersPageHeader"
      :orderPageTitle="t('orderPageTitle')"
      :filterOpened="filterOpened"
      :orderListParams="orderListParams"
      :filterOptions="manualFilterOptions"
      :filterBadgeCount="filterBadgeCount"
      filterType="manual"
      @dateSelected="selectDateOnClose"
      @clearSelectedDate="clearSelectDate"
      @searchFilterChange="searchFilter"
      @applyFilters="applyFilters"
      @updateFilterBadgeCount="updateFilterBadgeCount"
      @changeFilterOpened="changeFilterOpened"
    />

    <div class="orders-list">
      <OrderTable
        data-testid="ordersTable"
        :rows="orderList"
        :is-loading="tableLoading"
        :pagination="paginationInfo"
        :is-searched="isSearched"
        :columns="columns"
        order-type="manual"
        @pagination-changed="pageChanged"
      />
    </div>
  </div>
</template>

<script setup>
import { definePage } from 'vue-router/auto'
import { inject, ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { manualFilterOptions } from '@/constant/orders'
import { useAuthStore } from '@/stores/auth'
import 'flatpickr/dist/flatpickr.css'

definePage({
  name: 'orders',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Package\\Models\\Hub\\Order'],
  },
})

const { t } = useI18n({ useScope: 'global' })
const authStore = useAuthStore()

const rangeDate = ref(null)
const orderList = ref([])
const paginationInfo = ref({})
const axios = inject('axios')
const filterOpened = ref(false)
const filterBadgeCount = ref(0)
const tableLoading = ref(false)
const isSearched = ref(false)
const orderListParams = ref({
  selectDate: '',
  filterOption: '',
  search: '',
  page: 'page=1',
})
const getOrderAbortController = ref(null)
const getOrderList = async (clearPage = false) => {
  if (getOrderAbortController.value) {
    getOrderAbortController.value.abort()
  }

  getOrderAbortController.value = new AbortController()

  tableLoading.value = true
  let params = '?limit=25'

  if (clearPage) {
    orderListParams.value['page'] = `page=1`
  }

  Object.keys(orderListParams.value).forEach((item) => {
    if (orderListParams.value[item]) {
      params += `&${orderListParams.value[item]}`
    }
  })

  params = params.replace('&&', '&')

  if (axios) {
    const { data } = await axios
      .get(`/store/v1/orders${params}`, {
        signal: getOrderAbortController?.value?.signal,
        transformResponse: axios.defaults.transformResponse.concat((res) => {
          return {
            ...res,
            data: res.data?.map((order) => {
              return {
                ...order,
                total_price_paid:
                  order.total_net_price && !authStore.isOnlyCorporateStream
                    ? order.total_net_price
                    : order.total_price,
              }
            }),
          }
        }),
      })
      .finally(() => {
        tableLoading.value = false
      })

    orderList.value = data.data
    paginationInfo.value = data.pagination_info
  }

  isSearched.value = !!orderListParams?.value?.search?.length
}

const columns = ref([
  {
    name: t('orderTableOrderId'),
    key: 'code',
  },

  {
    name: t('orderTableDate'),
    key: 'created_at',
  },

  {
    name: t('orderTableUserName'),
    key: 'user',
    customHeaderClass: 'min-w-48',
    customTdClass: 'max-w-0',
  },

  {
    name: t('orderTableQuantity'),
    key: 'quantity',
    customHeaderClass: 'text-right',
    customClass: 'flex justify-end',
  },

  {
    name: t('orderTableTotalPrice'),
    key: 'total_price_paid',
    customHeaderClass: 'text-right',
    customClass: 'flex justify-end whitespace-nowrap',
  },

  {
    name: t('orderTableStatus'),
    key: 'status',
  },
])

const updateFilterBadgeCount = (badgeCount) => {
  filterBadgeCount.value = badgeCount
}

const applyFilters = async (params) => {
  filterOpened.value = false

  orderListParams.value['filterOption'] = params

  await getOrderList(true)
}

const changeFilterOpened = (filterStatus) => {
  filterOpened.value = filterStatus
}

const selectDateOnClose = (selectedDates, dateStr) => {
  let params = ''
  let dates = dateStr.split('to').map((item) => item.trim())

  if (!dates[0]) return

  dates.forEach((date, index) => {
    if (dates.length === 1) {
      params = `filter[created_at]=${date} - ${date}`
    } else if (index === 0) {
      params = `filter[created_at]=${date}`
    } else {
      params += ` - ${date}`
    }
  })

  if (orderListParams.value.selectDate === params) return

  orderListParams.value.selectDate = params
  getOrderList(true)
}

const clearSelectDate = () => {
  rangeDate.value = null
  orderListParams.value.selectDate = ''
  getOrderList(true)
}

const searchFilter = (searchString) => {
  if (orderListParams.value['search'] !== `filter[code]=${searchString}`) {
    orderListParams.value['search'] = searchString
      ? `filter[code]=${searchString}`
      : ''

    getOrderList(true)
  }
}

const pageChanged = async (page) => {
  orderListParams.value['page'] = `page=${page}`

  await getOrderList()
}

onMounted(() => {
  getOrderList()
})
</script>

<style lang="scss" scoped>
.orders {
  .orders-header {
    @apply flex justify-between mb-0 pb-4 border-solid border-[#CACACB];
    .orders-header-filter {
      .orders-header-filter-input {
        @apply ltr:pl-10 rtl:pr-10 bg-seashell-100;

        &::placeholder {
          @apply text-sm text-grey-500;
        }
      }

      .orders-header-filter-recentlybox,
      .orders-header-filter-searchbox {
        @apply absolute top-[43px] p-5 pb-[10px] w-full z-[2] bg-[#fafafa] border-[#dddddd] rounded-[7px] z-20;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        overflow: hidden;

        .order-header-filter-searched:nth-child(2n) p {
          @apply mt-2;
        }

        .filter-searchbox-item {
          @apply flex text-lg items-center text-grey-500 cursor-pointer h-[50px];
          &:nth-child(2n) {
            position: relative;

            &:after {
              content: '';
              display: block;
              position: absolute;
              width: 125%;
              height: 100%;
              left: -25px;
              z-index: -1;
              @apply bg-[#F3F3F3];
            }
          }
        }
      }

      .orders-header-filter-searchbox .filter-searchbox-item {
        &:nth-child(2n):after {
          display: none;
        }

        &:nth-child(2n + 1) {
          position: relative;

          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 125%;
            height: 100%;
            left: -25px;
            z-index: -1;

            @apply bg-[#F3F3F3];
          }
        }
      }

      &-buttons {
        @apply flex items-center gap-[15px] mt-[25px] flex-wrap;
        button {
          @apply cta-button-sm flex items-center gap-[6px] py-[8px] px-[11px] bg-seashell-700 border-[1px] border-solid border-[#B8B8B8] rounded-lg;
        }

        .export-options {
          @apply flex flex-col items-start absolute w-[218px] bg-[#FAFAFA] border-[#dddddd] rounded-[7px] drop-shadow-xl top-[50px] left-0 z-[2];

          .export-option-item-text {
            @apply p-5 cursor-pointer flex items-center max-h-[48px] min-h-[38px] rounded-[4px] text-grey-500;
          }
        }
      }
    }
  }
}

:global(.endRange) {
  @apply shadow-none;
}
</style>
