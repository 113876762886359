import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { axios } from '@/http'

export const useFeatureFlagsStore = defineStore('feature-flags', () => {
  const featureFlags = ref<string[]>([])

  async function initFeatureFlags() {
    const { data } = await axios.get('/feature/v1/features')
    featureFlags.value = data?.data
  }

  function isFeatureFlagEnabled(flag: string) {
    return featureFlags.value.includes(flag)
  }

  const corporateMode = computed (() => (
    isFeatureFlagEnabled('partner_hub.corporate_invitations')
  ))

  return {
    featureFlags,
    initFeatureFlags,
    corporateMode,
  }
})
