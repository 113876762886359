<template>
  <div
    class="max-w-500 my-60 px-2 lg:w-1/3"
    data-testid="partner-onboarding-page"
  >
    <div class="flex flex-col gap-6" data-testid="onboarding-content">
      <div class="flex flex-col gap-3" data-testid="onboarding-header">
        <h2
          class="font-ibm-plex-sans text-7 font-medium text-grey-800"
          data-testid="onboarding-title"
        >
          {{ $t('partnerOnboardingTitle') }}
        </h2>

        <span
          class="font-ibm-plex-sans text-4 font-normal text-grey-800"
          data-testid="onboarding-subtitle"
        >
          {{ $t('partnerOnboardingSubtitle') }}
        </span>
      </div>

      <RadioButtonGroup
        v-model="option"
        :options="options"
        data-testid="onboarding-options"
      />

      <NewButton
        type="cta"
        class="h-12"
        data-testid="onboarding-cta"
        @click="onClick"
      >
        {{ $t('partnerCta') }}
      </NewButton>

      <a
        :href="`mailto:partnerships@airalo.com?subject=${$t('partnerSignupContactSubject')}`"
      >
        <TrailButton class="w-full" type="secondary">
          {{ $t('partnerSignupContactUs') }}
        </TrailButton>
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OnboardingFlow } from '@/api/models/lead-application'
import type { RadioOption } from '@/components/RadioButtonGroup.vue'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { definePage } from 'vue-router/auto'

definePage({
  name: 'onboarding',
  meta: { requiresAuth: false },
  path: '/sign-up/onboarding',
})

const { t } = useI18n({ useScope: 'global' })
const { push } = useRouter()

const options: RadioOption<OnboardingFlow>[] = [
  {
    label: t('partnerOnboardingOption1Label'),
    value: 'CORPORATE',
  },
  {
    label: t('partnerOnboardingOption2Label'),
    value: 'RESELLER',
  },
  {
    label: t('partnerOnboardingOption3Label'),
    value: 'AFFILIATE',
  },
  {
    label: t('partnerOnboardingOption4Label'),
    value: 'OTHER',
  },
]

const option = ref<RadioOption<OnboardingFlow>['value']>(options[0].value)

const onClick = (): void => {
  if (option.value === 'AFFILIATE') {
    location.href =
      'https://app.impact.com/campaign-promo-signup/Airalo-The-Worlds-First-eSIM-store.brand?execution=e1s1'
    return
  }

  const onboardingRouteMap: Record<OnboardingFlow, string> = {
    CORPORATE: '/sign-up/onboarding/corporate',
    RESELLER: '/sign-up/onboarding/reseller',
    AFFILIATE: '#',
    OTHER: '/sign-up/onboarding/brand',
  }

  option.value && push(onboardingRouteMap[option.value])
}
</script>
