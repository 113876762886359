<template>
  <RadixTooltipProvider>
    <router-view />
  </RadixTooltipProvider>

  <TrailToaster />

  <div v-if="!buildMeta.isProduction" class="fixed right-3 bottom-3">
    <p>
      Build: {{ buildMeta.version }}
      <span v-if="buildMeta.date">Date: {{ buildMeta.date }}</span>
    </p>
  </div>
</template>

<script setup>
import { inject } from 'vue'
const buildMeta = inject('buildMeta')
</script>
