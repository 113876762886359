import { removeAppLoading, getStorageAccessToken } from '@/utils/auth'
import { createRouter, createWebHistory } from 'vue-router/auto'
import { useAuthStore } from '@/stores/auth'
import { hasPermissions } from '@/utils/permissions'
import i18n from '@/i18n'

function checkRedirectURL() {
  const { pathname, search } = window.location
  const currentURL = pathname + search
  if (currentURL) localStorage.setItem('ret_url', currentURL)
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  base: import.meta.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const tokenRedirectUrl = localStorage.getItem('ret_url')
    const storageAccessToken = getStorageAccessToken()
    const authStore = useAuthStore()

    if (!storageAccessToken) {
      checkRedirectURL()
      authStore.logout()
    } else if (tokenRedirectUrl && tokenRedirectUrl !== '/') {
      next(tokenRedirectUrl)
      localStorage.removeItem('ret_url')
    } else {
      next()
    }
  } else {
    const authStore = await useAuthStore()

    await authStore.initialize()

    if (authStore.hasUser) {
      next('/home')
    } else {
      next()
    }
  }

  removeAppLoading()
})

router.beforeEach(async (to, from, next) => {
  const routeName = to.name
  const translationKey = `components.nav.menu.${routeName}`
  document.title = `Airalo Partner Platform - ${i18n.global.t(translationKey)}`

  const authStore = useAuthStore()

  if (authStore.isInitializedComp === false && authStore.hasUser === false) {
    await authStore.initialize()
  }

  if (authStore.hasUser) {
    hasPermissions(to.meta.requiredPermissions) ? next() : next('/home')
  }

  next()
})

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath
  }
})

export default router
