<template>
  <div>
    <div
      class="relative h-[calc(100vh_-_289px)] md:h-[calc(100vh_-_189px)] w-full md:w-[calc(2_*_(100%_-_2rem)_/_3_+_2rem)] xl:w-[calc(2_*_(100%_+_14rem)_/_3_-_14rem)] grid grid-rows-[auto_auto_1fr] gap-y-8 md:pr-6"
    >
      <h3 class="text-trail-title1 text-trail-text-title-normal">
        {{ $t(buyEsimStore?.shoppingBagOptions?.headerTitle) }}
      </h3>

      <div
        v-if="buyEsimStore?.destinationsLoading"
        class="absolute z-9999 left-1/2 top-32 -translate-x-1/2 -translate-y-1/2"
      >
        <TrailIcon
          :size="36"
          color="highlight"
          icon="local:spinner"
          class="animate-spin"
        />
      </div>

      <ChooseDestination
        v-if="!buyEsimStore?.shoppingBagOptions?.isTopup"
        v-model:search-sims="searchSims"
        :loading="buyEsimStore?.destinationsLoading"
        :chosenDestination="chosenDestination"
        @getPackages="getPackages"
        @clearDestinations="clearDestinations"
      />

      <OperatorPackages
        v-if="!isModalTopup"
        :loading="buyEsimStore?.destinationsLoading"
        :chosenDestination="
          buyEsimStore?.shoppingBagOptions?.isTopup
            ? selectedTopupDestination
            : chosenDestination
        "
        :esimsQuantity="esimsQuantity"
        :hasCartError="hasCartError"
        :packageTitle="
          buyEsimStore?.shoppingBagOptions?.isTopup
            ? $t('buyEsimChooseTopupPackageTitle')
            : $t('buyEsimChoosePackageTitle')
        "
        @openPackageDetail="openPackageDetail"
        @addToShoppingBag="addToShoppingBag"
      />

      <TopupPackages
        v-if="isModalTopup"
        :esimsQuantity="esimsQuantity"
        :isLoading="isTopupsLoading"
        :topupOptions="topupOptions"
        :topupEsimOperator="topupEsimOperator"
        :topupEsimRegion="topupEsimRegion"
        @addToShoppingBag="addToShoppingBag"
      />
    </div>

    <ShoppingBagDesktop
      :orderPrice="orderPrice"
      :esimsQuantity="esimsQuantity"
      :placeOrderButtonLoading="placeOrderButtonLoading"
      :isOrderButtonDisabled="isOrderButtonDisabled"
      :hasCartError="hasCartError"
      :paymentMethod="paymentMethod"
      :cardBrand="cardBrand"
      :lastFourDigits="lastFourDigits"
      :expiryMonth="expiryMonth"
      :expiryYear="expiryYear"
      :balance="balance"
      :haveInsufficientFunds="haveInsufficientFunds"
      :paymentButtonText="paymentButtonText"
      :topupShoppingBag="topupShoppingBag"
      :topupEsimOperator="topupEsimOperator"
      :topupEsimRegion="topupEsimRegion"
      :topupEsimRegionImgUrl="topupEsimRegionImgUrl"
      @openChoosePaymentModal="openChoosePaymentModal"
      @openPackageDetailModal="openPackageDetail"
      @placeOrder="placeOrder"
      @removePackage="removeFromShoppingBag"
      @changePackagesAmount="changePackagesAmount"
    />

    <ShoppingBagMobile
      :orderPrice="orderPrice"
      :esimsQuantity="esimsQuantity"
      :placeOrderButtonLoading="placeOrderButtonLoading"
      :isOrderButtonDisabledMobile="isOrderButtonDisabledMobile"
      :mobileCartState="mobileCartState"
      :hasCartError="hasCartError"
      @openMobileCart="openMobileCart"
      @closeMobileCart="closeMobileCart"
      @placeOrderMobile="placeOrderMobile"
      @openPackageDetailModal="openPackageDetail"
      @removePackage="removeFromShoppingBag"
      @changePackagesAmount="changePackagesAmount"
    />

    <PackageDetailsModal
      :is-opened="showPackageDetail"
      :sim="packageDetailData"
      :available-packages="
        packageDetailData?.packages?.filter((item) => item.type === 'topup') ||
        []
      "
      :global-networks="globalNetworks"
      @clickClose="closePackageDetail()"
    />

    <ChoosePaymentModal
      :isShowAiraloCredits="true"
      :balance="balance"
      :haveInsufficientFunds="haveInsufficientFunds"
      :activeModal="isChoosePaymentOpened"
      @close="isChoosePaymentOpened = false"
      @setCard="setCard"
      @checkDeleteCard="checkDeleteCard"
      @onSelectPaymentMethod="onSelectPaymentMethod"
    />

    <TrailModal v-model="showOrderSuccessfulModal">
      <template #title>
        {{ $t('component.buy.esim.payment.success.header-title') }}
      </template>

      <BaseCard class="flex flex-col items-center max-w-128 px-8 py-6">
        <PaymentSuccessImage />

        <p class="mt-10 text-trail-title3 text-trail-text-title-normal">
          {{
            $t('component.buy.esim.payment.success.description-title', {
              orderCode,
            })
          }}
        </p>

        <p
          class="mt-2 text-trail-body2 text-center text-trail-text-body-normal"
        >
          {{ $t('component.buy.esim.payment.success.description') }}
        </p>
      </BaseCard>

      <div class="grid md:grid-cols-2 gap-4 mt-8">
        <TrailButton type="secondary" @click="goToEsimsList">
          {{ $t('component.buy.esim.payment.success.secondary-button-text') }}
        </TrailButton>

        <TrailButton @click="goToOrderDetail(orderCode)">
          {{ $t('component.buy.esim.payment.success.primary-button-text') }}
        </TrailButton>
      </div>
    </TrailModal>

    <AddBillingDetailsOverlay
      v-if="!hasBillingDetails"
      v-model:is-open="addBillingDetailsIsOpen"
    />
  </div>
</template>

<script setup>
import { getTopupOptions } from '@/api/manageEsims'
import { getCards } from '@/api/payment'
import { transformBillingSummaryResponse } from '@/api/transforms/financeTransforms'
import { useBillingDetails } from '@/composables/billing/use-billing-details'
import { useResponsiveness } from '@/composables/responsiveness'
import { useApiData } from '@/composables/useApiData'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import {
  EMPTY_ORDER_PRICE,
  MAXIMUM_ESIM_QUANTITY,
} from '@/constant/shoppingBag'
import { useAuthStore } from '@/stores/auth'
import { useBuyEsimStore } from '@/stores/buy-esim.store'
import { generateNetworks } from '@/utils/generateNetworks'
import {
  mapSims,
  mapTopupsForDiscount,
  mapTopupsForOrder,
} from '@/utils/mapSims'
import { removeAccents } from '@/utils/removeAccents'
import { scrollToShoppingBagTop } from '@/utils/scroll'
import { useMediaQuery } from '@vueuse/core'
import {
  onMounted,
  onUnmounted,
  computed,
  inject,
  ref,
  watch,
  watchEffect,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter, definePage } from 'vue-router/auto'
import PaymentSuccessImage from '@/assets/images/payment-success-image.svg?component'

definePage({
  name: 'esim-store',
  meta: { requiresAuth: true, requiredPermissions: ['buy-esims'] },
})

// Definitions
const { isMobile } = useResponsiveness()
const { hasBillingDetails } = useBillingDetails()
const { t } = useI18n({ useScope: 'global' })
const axios = inject('axios')
const stripe = inject('stripe')
const toast = useTrailToast()
const topupShoppingBag = ref([])
const topupOptions = ref([])
const isTopupsLoading = ref(false)
const maximumDifferentPackages = ref(9)
const orderId = ref('')
const orderCode = ref('')
const chosenDestination = ref(null)
const showPackageDetail = ref(false)
const isChoosePaymentOpened = ref(false)
const addBillingDetailsIsOpen = ref(false)
const placeOrderButtonLoading = ref(false)
const showOrderSuccessfulModal = ref(false)
const mobileCartState = ref('total') // 'total' || 'bag'
const packageDetailData = ref(null)
const globalNetworks = ref(null)
const buyEsimStore = useBuyEsimStore()
const authStore = useAuthStore()
const orderPrice = ref(EMPTY_ORDER_PRICE)
const isLargeScreen = useMediaQuery('(min-width: 640px)')
const calculationAbortController = ref(null)
const paymentMethod = ref(undefined)
const userSavedCards = ref([])
const { data: airaloCreditsBalance, fetchData: fetchAiraloCreditsBalance } =
  useApiData('finance/v1/balance/summary', {
    transformer: transformBillingSummaryResponse,
    immediate: false,
  })
const balance = computed(
  () =>
    airaloCreditsBalance.value?.available_balance || {
      amount: 0,
      currency: 'USD',
    }
)
const searchSims = ref('')
const router = useRouter()

// Card Data
const lastFourDigits = ref('')
const expiryMonth = ref('')
const expiryYear = ref('')
const cardBrand = ref('')
const stripeElements = ref('')
const cardId = ref('')
const stripeCardId = ref('')

// Computed
const differentPackagesAmount = computed(() => {
  return buyEsimStore?.shoppingBag?.length
})

const hasCartError = computed(() => {
  return (
    esimsQuantity.value > MAXIMUM_ESIM_QUANTITY ||
    differentPackagesAmount.value > maximumDifferentPackages.value
  )
})

const haveInsufficientFunds = computed(
  () => orderPrice.value.total.amount > balance.value.amount
)

const esimsQuantity = computed(() => {
  if (isModalTopup.value) {
    return topupShoppingBag?.value?.reduce((a, b) => a + b.quantity * 1, 0)
  } else {
    return buyEsimStore?.shoppingBag?.reduce((a, b) => a + b.quantity * 1, 0)
  }
})

const isOrderButtonDisabled = computed(() => {
  return (
    esimsQuantity.value === 0 ||
    paymentMethod.value === undefined ||
    (paymentMethod.value === 'airalo_credits' && haveInsufficientFunds.value)
  )
})

const isOrderButtonDisabledMobile = computed(() => {
  return esimsQuantity.value === 0
})

const paymentButtonText = computed(() => {
  return paymentMethod.value !== undefined
    ? t('buyEsimChangePayment')
    : t('buyEsimAddPayment')
})

const isModalTopup = computed(() => {
  return buyEsimStore?.shoppingBagOptions?.isTopup
})

const topupEsimIccid = computed(() => {
  return buyEsimStore?.shoppingBagOptions?.topupEsimIccid
})

const topupEsimOperator = computed(() => {
  return buyEsimStore?.shoppingBagOptions?.topupEsimOperator
})

const topupEsimRegion = computed(() => {
  return buyEsimStore?.shoppingBagOptions?.topupEsimRegion
})

const topupEsimRegionImgUrl = computed(() => {
  return buyEsimStore?.shoppingBagOptions?.topupEsimRegionImgUrl
})

const searchDestinations = (operator) => {
  const destinations = buyEsimStore?.destinations
  const operatorUpper = removeAccents(operator)?.toUpperCase()

  if (!destinations || Object.keys(destinations).length === 0) {
    return {}
  }

  const result = Object.values(destinations)
    .flat()
    .find((item) => removeAccents(item?.title?.toUpperCase()) === operatorUpper)

  return result || {}
}

const selectedTopupDestination = computed(() => {
  if (
    buyEsimStore?.shoppingBagOptions?.isTopup &&
    buyEsimStore?.shoppingBagOptions?.operator
  ) {
    return searchDestinations(buyEsimStore?.shoppingBagOptions?.operator)
  } else {
    return []
  }
})

// Methods
// Choose Destinations
const getPackages = (item) => {
  chosenDestination.value = item
}

const clearDestinations = () => {
  chosenDestination.value = null
  searchSims.value = ''
}

// Operators Packages
const openPackageDetail = (item) => {
  if (!isModalTopup.value) {
    showPackageDetail.value = true
    packageDetailData.value = item
    generateNetworks(packageDetailData.value, globalNetworks)
  }
}

// Cart
const openMobileCart = () => {
  mobileCartState.value = 'bag'
}

const closeMobileCart = () => {
  mobileCartState.value = 'total'
}

const closePackageDetail = () => {
  showPackageDetail.value = false
}

const addToShoppingBag = (item) => {
  if (isModalTopup.value) {
    let shoppingData = []

    if (topupShoppingBag.value?.length) {
      if (topupShoppingBag.value?.some((bag) => bag.id === item.id)) {
        shoppingData = topupShoppingBag.value?.map((shoppingItem) => {
          if (shoppingItem.id === item.id) {
            shoppingItem.quantity += item.quantity
          }
          return shoppingItem
        })
        shoppingData = [
          shoppingData.find((sItem) => sItem.id === item.id),
          ...topupShoppingBag.value.filter((sItem) => sItem.id !== item.id),
        ]
      } else {
        shoppingData = [item, ...topupShoppingBag.value]
      }
    } else {
      shoppingData = [item]
    }

    topupShoppingBag.value = shoppingData
  } else {
    buyEsimStore.addPackage(item)
  }
  scrollToShoppingBagTop()
}

const removeFromShoppingBag = (item) => {
  if (isModalTopup.value) {
    topupShoppingBag.value = topupShoppingBag.value.filter(
      (shoppingItem) => shoppingItem.id !== item.id
    )
  } else {
    buyEsimStore.removePackage(item)
  }
}

const changePackagesAmount = (packageName, value) => {
  if (isModalTopup.value) {
    const changedShoppingBag = topupShoppingBag?.value
    const shoppingItem = topupShoppingBag.value?.find(
      (bag) => bag.slug === packageName
    )
    if (shoppingItem) {
      shoppingItem.quantity = value
      topupShoppingBag.value = changedShoppingBag
    }
  } else {
    buyEsimStore.changePackagesAmount(packageName, value)
  }
}

// Payments
const openChoosePaymentModal = () => {
  addBillingDetailsIsOpen.value = true
  isChoosePaymentOpened.value = true
}

const clearCardDetails = () => {
  lastFourDigits.value = ''
  cardBrand.value = ''
  expiryMonth.value = ''
  expiryYear.value = ''
  cardId.value = ''
}

const clearState = () => {
  chosenDestination.value = null
  showPackageDetail.value = false
  packageDetailData.value = null
  clearCardDetails()
}

const setCard = ({ cardDetails, elements }) => {
  paymentMethod.value = 'stripe_card'
  lastFourDigits.value = cardDetails.card.last4
  expiryMonth.value = cardDetails.card.exp_month
  expiryYear.value = cardDetails.card.exp_year
  cardBrand.value = cardDetails.card.brand

  cardId.value = cardDetails.card.id
  stripeCardId.value = cardDetails.id

  stripeElements.value = elements

  if (isMobile.value) {
    placeOrder()
  }
}

const onSelectPaymentMethod = (method) => {
  paymentMethod.value = method

  if (isMobile.value) {
    placeOrder()
  }
}

const checkDeleteCard = (id) => {
  if (cardId.value === id) {
    clearCardDetails()
  }
}

const placeOrderMobile = () => {
  openChoosePaymentModal()
}

const placeOrder = async () => {
  if (paymentMethod.value !== undefined) {
    placeOrderButtonLoading.value = true

    let sims
    let request

    if (isModalTopup.value) {
      sims = mapTopupsForOrder(topupShoppingBag.value, topupEsimIccid.value)
      request = { sims: [], topups: sims }
    } else {
      sims = mapSims(buyEsimStore?.shoppingBag)
      request = { sims, topups: [] }
    }

    let response

    const card_id =
      paymentMethod.value === 'airalo_credits' ? undefined : cardId?.value

    try {
      response = await axios.post('/store/v1/orders', {
        ...request,
        payment_details: {
          method: paymentMethod.value,
          card_id,
        },
      })
    } catch (err) {
      console.error(err)
    }

    orderCode.value = response?.data?.code
    orderId.value = response?.data?.id

    if (orderCode.value) {
      const responseOrderDetails = await axios.post(
        `/store/v1/orders/${orderId.value}/payment`
      )

      let stripePaymentResponse

      if (
        paymentMethod.value === 'stripe_card' &&
        responseOrderDetails.data.status !== 'authorized'
      ) {
        stripePaymentResponse = await stripe.confirmCardPayment(
          responseOrderDetails.data.provider_payload.client_secret,
          {
            payment_method: stripeCardId.value,
          }
        )
      }

      if (
        paymentMethod.value === 'airalo_credits' ||
        responseOrderDetails.data.status === 'authorized' ||
        stripePaymentResponse?.paymentIntent
      ) {
        if (buyEsimStore.shoppingBagOptions.isTopup) {
          topupShoppingBag.value = []
        } else {
          await buyEsimStore.clearShoppingBag()
          clearDestinations()
        }

        showOrderSuccessfulModal.value = true
        placeOrderButtonLoading.value = false
        authStore.initialize()
      } else {
        placeOrderButtonLoading.value = false
        if (
          stripePaymentResponse?.error &&
          stripePaymentResponse?.error.type === 'card_error'
        ) {
          const error = stripePaymentResponse?.error.message
          toast.negative({
            content: t('buyEsimPaymentFailStripeMessage', {
              reason: error[0].toLowerCase() + error.slice(1),
            }),
          })
        } else {
          toast.negative({
            content: t('buyEsimPaymentFailMessage'),
          })
        }
      }
    } else {
      placeOrderButtonLoading.value = false
      toast.negative({
        content: t('buyEsimPaymentFailMessage'),
      })
    }
  }
}

const getOrderPrice = async (sims) => {
  if (calculationAbortController.value) {
    calculationAbortController.value.abort()
  }

  calculationAbortController.value = new AbortController()

  try {
    const { data } = await axios.post(
      `/store/v1/orders/calculate`,
      {
        sims,
      },
      { signal: calculationAbortController?.value?.signal }
    )
    orderPrice.value = data
  } catch (err) {
    console.error('Something went wrong during order calculation', err)
  }
}

const getOrderPriceForCartType = async () => {
  if (isModalTopup.value) {
    if (topupShoppingBag.value.length === 0) {
      orderPrice.value = EMPTY_ORDER_PRICE
      return
    }

    const orderSims = mapTopupsForDiscount(topupShoppingBag.value)
    await getOrderPrice(orderSims)
  } else {
    const { isShoppingBagEmpty, shoppingBag } = buyEsimStore

    if (isShoppingBagEmpty) {
      if (orderPrice.value.total.amount !== 0) {
        calculationAbortController.value.abort()
      }
      orderPrice.value = EMPTY_ORDER_PRICE

      return
    }

    const orderSims = mapSims(shoppingBag)
    await getOrderPrice(orderSims)
  }
}

const fetchUserSavedCard = async () => {
  const cards = await getCards()
  userSavedCards.value = cards.map((item) => ({
    id: item.payment_method_id,
    user_id: item.user_id,
    created_at: item.created_at,
    company_id: item.company_id,
    card: {
      id: item.id,
      brand: item.brand,
      country: item.country,
      last4: item.last4,
      exp_month: item.exp_month,
      exp_year: item.exp_year,
      three_d_secure_usage: {
        supported: item.supports3ds,
      },
    },
  }))
}

const checkDefaultCard = async () => {
  const defaultCardSettings = authStore.defaultBuyEsimCardSettings
  if (defaultCardSettings?.card_id) {
    await fetchUserSavedCard()
    const savedCardDetail = userSavedCards.value.find(
      (item) => item.card.id === defaultCardSettings.card_id
    )

    if (savedCardDetail) {
      const cardDetails = {
        cardDetails: {
          id: savedCardDetail.id,
          card: {
            last4: savedCardDetail?.card.last4,
            exp_month: savedCardDetail?.card.exp_month,
            exp_year: savedCardDetail?.card.exp_year,
            brand: savedCardDetail?.card.brand,
            id: savedCardDetail?.card.id,
          },
        },
        elements: '',
      }

      setCard(cardDetails)
    }
  } else {
    if (defaultCardSettings?.method) {
      paymentMethod.value = defaultCardSettings.method
    }
  }
}

const goToEsimsList = () => {
  showOrderSuccessfulModal.value = false
  router.push('/manage-esims')
}

const goToOrderDetail = (orderCode) => {
  showOrderSuccessfulModal.value = false
  router.push(`/orders/${orderCode}`)
}

watch(
  [() => buyEsimStore.shoppingBag, () => topupShoppingBag.value],
  async () => {
    getOrderPriceForCartType()
  },
  { deep: true, immediate: true }
)

onMounted(async () => {
  getOrderPriceForCartType()
  fetchAiraloCreditsBalance()

  if (isModalTopup.value) {
    isTopupsLoading.value = true

    const response = await getTopupOptions(
      buyEsimStore.shoppingBagOptions.topupEsimId
    )

    topupOptions.value = response?.data

    isTopupsLoading.value = false
  }
  checkDefaultCard()
})

onUnmounted(() => {
  topupShoppingBag.value = []
  buyEsimStore.resetShoppingBagOptions()
  clearState()
})

watchEffect(() => {
  esimsQuantity.value = buyEsimStore?.shoppingBag?.reduce(
    (a, b) => a + b.quantity * 1,
    0
  )

  if (isLargeScreen.value === true) {
    mobileCartState.value = 'total'
  }
})
</script>
