<template>
  <div>
    <UsersTable />
  </div>
</template>

<script setup>
import { definePage } from 'vue-router/auto'

definePage({
  name: 'users',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\User\\Models\\User'],
  },
})
</script>
