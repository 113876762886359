<template>
  <RadixToastRoot
    :duration="timeout"
    :open="open"
    class="bg-trail-surface-tertiary-normal rounded-lg pl-3 shadow-trail transition-all data-[swipe=move]:translate-x-[--radix-toast-swipe-move-x] data-[swipe=move]:transition-none data-[swipe=cancel]:translate-x-0 data-[state=closed]:animate-trail-fade-slide-out-to-right data-[state=open]:animate-trail-fade-slide-in-from-right"
    data-testid="trailToast"
    @update:open="emit('update:open', $event)"
  >
    <RadixToastDescription class="flex items-start">
      <div class="flex grow items-start my-2 min-w-0">
        <div
          :class="['p-2 rounded-2xl', toastColors]"
          data-testid="trailToastIconWrapper"
        >
          <TrailIcon :icon="toastIcon" data-testid="trailToastIcon" />
        </div>

        <p
          class="text-trail-text-body-normal self-center text-trail-body3 ml-2 min-w-0 break-words"
          data-testid="trailToastContent"
        >
          {{ content }}
        </p>
      </div>

      <RadixToastClose
        class="pt-2 pr-2 w-11 h-11 shrink-0 flex justify-end"
        data-testid="trailToastClose"
      >
        <TrailIcon icon="heroicons:x-mark" size="small" />
      </RadixToastClose>
    </RadixToastDescription>

    <RadixToastAction v-if="action" :alt-text="action.text" as-child>
      <TrailButton
        type="tertiary"
        size="small"
        class="mb-1"
        data-testid="trailToastAction"
        @click="action.onClick"
      >
        {{ action.text }}
      </TrailButton>
    </RadixToastAction>
  </RadixToastRoot>
</template>

<script setup lang="ts">
import { computed } from 'vue'

export type TrailToastProps = {
  type: 'normal' | 'positive' | 'negative' | 'warning'
  content: string
  open: boolean
  timeout?: number
  action?: {
    text: string
    onClick: () => void
  }
}

const props = withDefaults(defineProps<TrailToastProps>(), {
  timeout: 5000,
})

const emit = defineEmits<{
  'update:open': [value: boolean]
}>()

const toastIcon = computed(
  () =>
    ({
      normal: 'heroicons:information-circle',
      positive: 'heroicons:check-circle',
      negative: 'heroicons:x-circle',
      warning: 'heroicons:exclaimation-circle',
    })[props.type]
)

const toastColors = computed(
  () =>
    ({
      normal: 'bg-trail-surface-secondary-normal text-trail-icon-normal',
      positive: 'bg-trail-surface-tertiary-positive text-trail-icon-positive',
      negative: 'bg-trail-surface-tertiary-negative text-trail-icon-negative',
      warning: 'bg-trail-surface-tertiary-warning text-trail-icon-warning',
    })[props.type]
)
</script>
