<template>
  <div>
    <div
      v-if="fetchingBrandSettings"
      class="absolute left-1/2 -translate-x-1/2 top-72"
    >
      <TrailIcon
        icon="local:spinner"
        color="highlight"
        class="w-8 h-8 animate-spin"
      />
    </div>

    <BrandForm
      :brand-settings="brandSettings"
      :loading="updatingBrandSettings"
      :class="{ 'opacity-50': fetchingBrandSettings }"
      @submit="handleSubmitForm"
    >
      <template #header>
        <div class="flex flex-wrap items-start gap-4 mb-4">
          <h3 class="text-trail-title1 text-trail-text-title-normal">
            {{ $t('components.nav.menu.edit-brand') }} {{ settingsName }}
          </h3>

          <TrailBadge v-if="isDefault" type="highlight">
            {{ $t('editBrandSettingsBadgeDefaultBrand') }}
          </TrailBadge>

          <TrailBadge v-else-if="isDeactivated" type="negative">
            {{ $t('editBrandSettingsBadgeDeactivated') }}
          </TrailBadge>

          <ChangeBrandStatusDialogue
            v-if="brandSettings"
            :brand-id="brandId"
            :settings-name="brandSettings?.settings_name"
            :action="updateStatusAction"
            @status-updated="fetchBrandSettings"
          />
        </div>
      </template>
    </BrandForm>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { definePage, useRoute, useRouter } from 'vue-router/auto'
import { useI18n } from 'vue-i18n'
import { useApiData } from '@/composables/useApiData'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import type { BrandSettings } from '@/models/brandSettingsModels'

definePage({
  name: 'edit-brand',
  meta: {
    requiresAuth: true,
    requiredPermissions: [], // TODO: add permission
  },
})

const { t } = useI18n()

const route = useRoute<'edit-brand'>()
const router = useRouter()
const brandId = computed(() => route.params.id)

const {
  data: brandSettings,
  fetchData: fetchBrandSettings,
  loading: fetchingBrandSettings,
  error: fetchBrandSettingsError,
} = useApiData<BrandSettings>(() => `/company/v1/brands/${brandId.value}`)

const settingsName = computed(() => {
  const settings = brandSettings.value?.settings_name
  return settings ? `[${settings}]` : ''
})

const isDefault = computed(() => brandSettings.value?.is_default)
const isDeactivated = computed(
  () => brandSettings.value?.status === 'deactivated'
)

const {
  updateData: updateBrandSettings,
  loading: updatingBrandSettings,
  error: updateBrandSettingsError,
} = useApiData(() => `/company/v1/brands/${brandId.value}`, {
  immediate: false,
  removeEmptyValuesFromRequest: false,
  config: {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  },
})

const { positive, negative } = useTrailToast()

const handleSubmitForm = async (formData: FormData) => {
  await updateBrandSettings(formData)

  if (updateBrandSettingsError.value) {
    negative({
      content: t('errorTitle'),
    })
  } else {
    positive({
      content: t('brandFormSuccessEditToast'),
    })
    fetchBrandSettings()
  }
}

const updateStatusAction = computed(() =>
  isDeactivated.value ? 'Activate' : 'Deactivate'
)

watch(fetchBrandSettingsError, (brandSettingsError) => {
  if (brandSettingsError) {
    negative({
      content: t('brandFormCannotFindBrand'),
    })
    router.push('/platform/company-settings/brand-settings')
  }
})
</script>
