<template>
  <div class="max-w-500 my-60 px-2 lg:w-1/3" data-testid="brand-step-page">
    <BrandStep data-testid="brand-step" />
  </div>
</template>

<script lang="ts" setup>
import { definePage } from 'vue-router/auto'

definePage({
  name: 'onboarding-brand',
  meta: { requiresAuth: false },
  path: '/sign-up/onboarding/brand',
})
</script>
