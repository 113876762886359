import _definePage_default_0 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/[...404].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/forgot-password.vue?definePage&vue'
import _definePage_default_3 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/index.vue?definePage&vue'
import _definePage_default_4 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/already-registered.vue?definePage&vue'
import _definePage_default_5 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/brand/index.vue?definePage&vue'
import _definePage_default_6 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/corporate/index.vue?definePage&vue'
import _definePage_default_7 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/reseller/index.vue?definePage&vue'
import _definePage_default_8 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/add-credits.vue?definePage&vue'
import _definePage_default_9 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/analytics.vue?definePage&vue'
import _definePage_default_10 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/api-integration.vue?definePage&vue'
import _definePage_default_11 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/api-orders/index.vue?definePage&vue'
import _definePage_default_12 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/api-orders/[id].vue?definePage&vue'
import _definePage_default_13 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/billing/index.vue?definePage&vue'
import _definePage_default_14 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/billing/[id].vue?definePage&vue'
import _definePage_default_15 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/company-settings/[[tab]].vue?definePage&vue'
import _definePage_default_16 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/company-settings/brand-settings/[id].vue?definePage&vue'
import _definePage_default_17 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/company-settings/brand-settings/new-brand.vue?definePage&vue'
import _definePage_default_18 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/credits.vue?definePage&vue'
import _definePage_default_19 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/esims-store.vue?definePage&vue'
import _definePage_default_20 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/help.vue?definePage&vue'
import _definePage_default_21 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/home.vue?definePage&vue'
import _definePage_default_22 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/manage-esims.vue?definePage&vue'
import _definePage_default_23 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/orders/index.vue?definePage&vue'
import _definePage_default_24 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/orders/[id].vue?definePage&vue'
import _definePage_default_25 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/users/index.vue?definePage&vue'
import _definePage_default_26 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/users/[id].vue?definePage&vue'
import _definePage_default_27 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform.vue?definePage&vue'
import _definePage_default_28 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/reset-password.vue?definePage&vue'
import _definePage_default_29 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/sign-in.vue?definePage&vue'
import _definePage_default_30 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/sign-in-sso.vue?definePage&vue'
import _definePage_default_31 from '/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/sign-up.vue?definePage&vue'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/index.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/:404(.*)',
    name: '404',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/[...404].vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  _mergeRouteRecord(
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/forgot-password.vue'),
    /* no children */
  },
  _definePage_default_2
  ),
  {
    path: '/onboarding',
    name: '/onboarding',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding.vue'),
    children: [
  _mergeRouteRecord(
      {
        path: '/sign-up/onboarding',
        name: 'onboarding',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/index.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'already-registered',
        name: 'already-registered',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/already-registered.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
      {
        path: 'api_partner',
        /* internal name: '/onboarding/api_partner' */
        /* no component */
        children: [
          {
            path: '',
            name: '/onboarding/api_partner/',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/api_partner/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'brand',
        /* internal name: '/onboarding/brand' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '/sign-up/onboarding/brand',
            name: 'onboarding-brand',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/brand/index.vue'),
            /* no children */
          },
  _definePage_default_5
  )
        ],
      },
      {
        path: 'corporate',
        /* internal name: '/onboarding/corporate' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '/sign-up/onboarding/corporate',
            name: 'onboarding-corporate',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/corporate/index.vue'),
            /* no children */
          },
  _definePage_default_6
  )
        ],
      },
      {
        path: 'reseller',
        /* internal name: '/onboarding/reseller' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '/sign-up/onboarding/reseller',
            name: 'onboarding-reseller',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/onboarding/reseller/index.vue'),
            /* no children */
          },
  _definePage_default_7
  )
        ],
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/platform',
    name: '/platform',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform.vue'),
    alias: [],
    children: [
  _mergeRouteRecord(
      {
        path: 'add-credits',
        name: 'add-credits',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/add-credits.vue'),
        alias: ["/add-credits"],
        /* no children */
      },
  _definePage_default_8
  ),
  _mergeRouteRecord(
      {
        path: 'analytics',
        name: 'analytics',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/analytics.vue'),
        alias: ["/analytics"],
        /* no children */
      },
  _definePage_default_9
  ),
  _mergeRouteRecord(
      {
        path: 'api-integration',
        name: 'api-integration',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/api-integration.vue'),
        alias: ["/api-integration"],
        /* no children */
      },
  _definePage_default_10
  ),
      {
        path: 'api-orders',
        /* internal name: '/platform/api-orders' */
        /* no component */
        alias: ["/api-orders"],
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'api-orders',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/api-orders/index.vue'),
            alias: ["/api-orders"],
            /* no children */
          },
  _definePage_default_11
  ),
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'api-order-detail',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/api-orders/[id].vue'),
            alias: ["/api-orders/:id"],
            /* no children */
          },
  _definePage_default_12
  )
        ],
      },
      {
        path: 'billing',
        /* internal name: '/platform/billing' */
        /* no component */
        alias: ["/billing"],
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'billing',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/billing/index.vue'),
            alias: ["/billing"],
            /* no children */
          },
  _definePage_default_13
  ),
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'billing-details',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/billing/[id].vue'),
            alias: ["/billing/:id"],
            /* no children */
          },
  _definePage_default_14
  )
        ],
      },
      {
        path: 'company-settings',
        /* internal name: '/platform/company-settings' */
        /* no component */
        alias: ["/company-settings"],
        children: [
  _mergeRouteRecord(
          {
            path: ':tab?',
            name: 'company-settings',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/company-settings/[[tab]].vue'),
            alias: ["/company-settings/:tab?"],
            /* no children */
          },
  _definePage_default_15
  ),
          {
            path: 'brand-settings',
            /* internal name: '/platform/company-settings/brand-settings' */
            /* no component */
            alias: ["/company-settings/brand-settings"],
            children: [
  _mergeRouteRecord(
              {
                path: ':id',
                name: 'edit-brand',
                component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/company-settings/brand-settings/[id].vue'),
                alias: ["/company-settings/brand-settings/:id"],
                /* no children */
              },
  _definePage_default_16
  ),
  _mergeRouteRecord(
              {
                path: 'new-brand',
                name: 'new-brand',
                component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/company-settings/brand-settings/new-brand.vue'),
                alias: ["/company-settings/brand-settings/new-brand"],
                /* no children */
              },
  _definePage_default_17
  )
            ],
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'credits',
        name: 'credits',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/credits.vue'),
        alias: ["/credits"],
        /* no children */
      },
  _definePage_default_18
  ),
  _mergeRouteRecord(
      {
        path: 'esims-store',
        name: 'esim-store',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/esims-store.vue'),
        alias: ["/esims-store"],
        /* no children */
      },
  _definePage_default_19
  ),
  _mergeRouteRecord(
      {
        path: 'help',
        name: 'help',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/help.vue'),
        alias: ["/help"],
        /* no children */
      },
  _definePage_default_20
  ),
  _mergeRouteRecord(
      {
        path: 'home',
        name: 'home',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/home.vue'),
        alias: ["/home"],
        /* no children */
      },
  _definePage_default_21
  ),
  _mergeRouteRecord(
      {
        path: 'manage-esims',
        name: 'manage-esims',
        component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/manage-esims.vue'),
        alias: ["/manage-esims"],
        /* no children */
      },
  _definePage_default_22
  ),
      {
        path: 'orders',
        /* internal name: '/platform/orders' */
        /* no component */
        alias: ["/orders"],
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'orders',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/orders/index.vue'),
            alias: ["/orders"],
            /* no children */
          },
  _definePage_default_23
  ),
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'order-detail',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/orders/[id].vue'),
            alias: ["/orders/:id"],
            /* no children */
          },
  _definePage_default_24
  )
        ],
      },
      {
        path: 'users',
        /* internal name: '/platform/users' */
        /* no component */
        alias: ["/users"],
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'users',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/users/index.vue'),
            alias: ["/users"],
            /* no children */
          },
  _definePage_default_25
  ),
  _mergeRouteRecord(
          {
            path: ':id',
            name: 'user-detail',
            component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/platform/users/[id].vue'),
            alias: ["/users/:id"],
            /* no children */
          },
  _definePage_default_26
  )
        ],
      }
    ],
  },
  _definePage_default_27
  ),
  _mergeRouteRecord(
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/reset-password.vue'),
    /* no children */
  },
  _definePage_default_28
  ),
  _mergeRouteRecord(
  {
    path: '/sign-in',
    name: 'sign-in',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/sign-in.vue'),
    /* no children */
  },
  _definePage_default_29
  ),
  _mergeRouteRecord(
  {
    path: '/sign-in-sso',
    name: 'sign-in-sso',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/sign-in-sso.vue'),
    /* no children */
  },
  _definePage_default_30
  ),
  _mergeRouteRecord(
  {
    path: '/sign-up',
    name: 'sign-up',
    component: () => import('/home/runner/work/airalo-partner-panel-frontend/airalo-partner-panel-frontend/src/pages/sign-up.vue'),
    /* no children */
  },
  _definePage_default_31
  )
]
