<template>
  <div class="grid gap-6 px-6" data-testid="addCreditsContainer">
    <div class="flex flex-col gap-2.5">
      <router-link class="w-8 h-8 active:opacity-70" :to="{ name: 'credits' }">
        <BaseIcon
          icon="heroicons-outline:arrow-left"
          :size="24"
          class="cursor-pointer h-8 rounded-3xl bg-seashell-100 border border-seashell-900 text-grey-500 flex items-center justify-center"
        />
      </router-link>

      <h3 class="text-grey-800">{{ $t('addCreditsPageTitle') }}</h3>
    </div>

    <div class="flex gap-6">
      <AddCreditsChooseAmount @setAmount="(value) => (amount = value)" />

      <AddCreditsPaymentSummary :amount="amount" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { definePage } from 'vue-router/auto'

definePage({
  name: 'add-credits',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.finance.airalo-credits.add'],
  },
})

const amount = ref<number>(0)
</script>
